import styled, { css } from 'styled-components';
import Link from 'gatsby-plugin-transition-link';

export const StyledBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.animated ? 0 : 1)};

  &.primary .Text {
    color: ${(props) => props.theme.colors.white};
  }

  &.secondary .Text {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export const Nav = styled.nav`
  &.isTablet {
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export const NavList = styled.ul`
  display: flex;
  align-itens: center;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;

  & li:first-child {
    margin: 0;
  }
`;

export const NavItem = styled.li`
  margin: 0;

  &.primary {
    color: ${(props) => props.theme.colors.white};
  }

  &.secondary {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export const siteBackTypographyStyles = css`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-weight: ${(props) => props.theme.fontWeight.eyebrow_text_mobile};
  font-size: ${(props) => props.theme.fonts.eyebrow_text_mobile};
  line-height: ${(props) => props.theme.lineHeight.h4};
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.eyebrow_text_tablet};
  }
`;

export const StyledLink = styled(Link)`
  ${siteBackTypographyStyles};
  text-decoration: none;

  &.primary {
    color: ${(props) => props.theme.colors.white};
  }

  &.secondary {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export const Text = styled.span`
  font-weight: 700;

  &.isTablet {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }

  &.primary {
    color: ${(props) => props.theme.colors.white};
  }

  &.secondary {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export const BreadcrumbsContent = styled.div``;
