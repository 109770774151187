import styled from 'styled-components';

export const WordWrapper = styled.div`
  display: inline-flex;
  overflow-y: hidden;
  margin-right: ${(props) => (props.wordMargin ? props.wordMargin : '17px')};
`;

export const Word = styled.span`
  display: inline-block;
`;
