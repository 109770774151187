import styled, { css } from 'styled-components';

export const StyledResourceBlock = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue};

  &.grey {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  }
`;

export const Title = styled.h2`
  margin-right: 150px;
  max-width: 408px;
  margin: 30px 0;

  font-size: ${(props) => props.theme.fonts.body_l_mobile};
  font-weight: ${(props) => props.theme.fontWeight.h2_mobile};
  line-height: ${(props) => props.theme.lineHeight.h2_mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.body_l_tablet};
    font-weight: ${(props) => props.theme.fontWeight.h2_tablet};
    line-height: ${(props) => props.theme.lineHeight.h2_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.body_l_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h2_desktop};
    line-height: ${(props) => props.theme.lineHeight.h2_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.body_l_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.h2_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.h2_large_desktop};
  }
`;

export const CtasList = styled.div`
  display: flex;
  flex-wrap: wrap;
  // padding-bottom: 20px;

  // &.isMargin {
  //   padding-bottom: 0;
  // }
`;

export const Cta = styled.div`
  margin-right: 25px;
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
`;

export const Body = styled.div`
  margin-bottom: 20px;
  max-width: 608px;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 30px;
  }
`;

export const ResourceHead = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TagsDescktopContainer = styled.div`
  display: none;

  @media (min-width: 500px) {
    display: block;
  }
`;

export const TagsMobileContainer = styled.div`
  display: block;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 65px;
  padding-bottom: 20px;

  @media (min-width: 500px) {
    height: 85px;
    padding-bottom: 0;
  }
`;

export const TagContainer = styled.div`
  margin-left: 10px;
  width: 54px;
  height: auto;
  transform: rotate(10deg);

  &.multilingual {
    transform: rotate(-10deg);
  }

  &.icon-24 {
    width: 67px;
    transform: rotate(0);
  }

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 74px;

    &.icon-24 {
      width: 93px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 30px;
  }
`;
