import React from 'react';
import {
  StyledResourceBlock,
  Title,
  CtasList,
  Cta,
  Body,
  TagContainer,
  Tags,
  ResourceHead,
  TagsDescktopContainer,
  TagsMobileContainer,
} from './styles';
import Button from 'components/UI/Button';
import getRichText from 'utils/functions/getRichText';
import Icon from 'components/Icon/';

const ResourceBlock = ({ title, body, ctas, tags, className }) => {
  return (
    <StyledResourceBlock className={className ? className : ''}>
      <ResourceHead>
        {title && <Title>{title}</Title>}
        <TagsDescktopContainer>
          <Tags>
            {tags &&
              tags.map((tag) => {
                if (tag === '24/7') {
                  return (
                    <TagContainer className="icon-24">
                      <Icon type="24/7" />
                    </TagContainer>
                  );
                } else if (tag === 'Multilingual') {
                  return (
                    <TagContainer className="multilingual">
                      <Icon type="multilingual" />
                    </TagContainer>
                  );
                } else if (tag === 'Bilingual') {
                  return (
                    <TagContainer>
                      <Icon type="fr-en" />
                    </TagContainer>
                  );
                }
              })}
          </Tags>
        </TagsDescktopContainer>
      </ResourceHead>
      {body && <Body>{getRichText(body)}</Body>}
      <CtasList className={tags ? 'isMargin' : ''}>
        {ctas &&
          ctas.map((cta, index) => {
            if (index === 0) {
              return cta.phoneNumber ? (
                <Cta>
                  <Button
                    openInNewTab={cta.openInNewTab}
                    theme="primary"
                    className="Button-Phone"
                    withIcon="phone"
                    to={cta.phoneNumber}
                  >
                    {cta.title}
                  </Button>
                </Cta>
              ) : (
                <Cta>
                  <Button
                    openInNewTab={cta.openInNewTab}
                    theme="primary"
                    className="Button-External"
                    withIcon="external"
                    isExternal={true}
                    to={cta.externalLinkOrAnchor}
                  >
                    {cta.title}
                  </Button>
                </Cta>
              );
            } else {
              return cta.phoneNumber ? (
                <Cta>
                  <Button
                    openInNewTab={cta.openInNewTab}
                    theme="secondary"
                    className="Button-Phone isTransparent"
                    withIcon="phone"
                    to={cta.phoneNumber}
                  >
                    {cta.title}
                  </Button>
                </Cta>
              ) : (
                <Cta>
                  <Button
                    openInNewTab={cta.openInNewTab}
                    theme="secondary"
                    className="Button-External isTransparent"
                    withIcon="external"
                    isExternal={true}
                    to={cta.externalLinkOrAnchor}
                  >
                    {cta.title}
                  </Button>
                </Cta>
              );
            }
          })}
      </CtasList>
      {tags && (
        <TagsMobileContainer>
          <Tags>
            {tags.map((tag) => {
              if (tag === '24/7') {
                return (
                  <TagContainer className="icon-24">
                    <Icon type="24/7" />
                  </TagContainer>
                );
              } else if (tag === 'Multilingual') {
                return (
                  <TagContainer className="multilingual">
                    <Icon type="multilingual" />
                  </TagContainer>
                );
              } else if (tag === 'Bilingual') {
                return (
                  <TagContainer>
                    <Icon type="fr-en" />
                  </TagContainer>
                );
              }
            })}
          </Tags>
        </TagsMobileContainer>
      )}
    </StyledResourceBlock>
  );
};

export default ResourceBlock;
