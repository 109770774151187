import React from 'react';
import { navigate } from '@reach/router';
import BackLink from 'components/UI/BackLink';
import {
  StyledBreadcrumbs,
  StyledLink,
  NavList,
  NavItem,
  Text,
  Nav,
  BreadcrumbsContent,
} from './styles';

const Breadcrumbs = ({
  props,
  theme = 'primary',
  breadcrumbsText,
  breadcrumbsTextTablet,
  setRef,
  exitDelay,
  onClick,
  animated,
}) => {
  const { location } = props;
  let current = undefined;
  let parent = undefined;

  const resourcePage = props?.data?.contentfulResourcePage;
  const parentResources = props?.data?.contentfulParentResourcesPage;

  if (resourcePage) {
    current = {
      handle: resourcePage?.handle,
      title: resourcePage?.title,
    };

    const parentResourcePage =
      resourcePage?.parentResource?.parent_resources___page;

    parent = [
      {
        handle: '/',
        title: 'Accueil',
      },
    ];

    if (parentResourcePage) {
      parent.push({
        handle: parentResourcePage[0]?.handle,
        title: parentResourcePage[0]?.title,
      });
    }

    parent.push({
      handle: resourcePage?.parentResource?.handle,
      title: resourcePage?.parentResource?.title,
    });
  }

  if (parentResources) {
    current = {
      handle: parentResources?.handle,
      title: parentResources?.title,
    };

    parent = [
      {
        handle: '/',
        title: 'Accueil',
      },
    ];
  }

  let backLinkPath = '/';
  let firstSpecificHandle = '';
  const relatedResources =
    props?.data?.contentfulResourcePage?.parentResource?.relatedResources;
  if (relatedResources) {
    firstSpecificHandle = relatedResources[0].handle;
  }

  if (parent.length === 3) {
    backLinkPath = backLinkPath + `${parent[1].handle}/#dropdown`;
  }

  if (parent.length == 2) {
    backLinkPath = backLinkPath + `${parent[1].handle}/`;
  }

  const backLinkClick = () => {
    if (onClick) {
      onClick();
    }
    setTimeout(
      () => {
        navigate(backLinkPath);
      },
      exitDelay ? exitDelay * 1000 : 0
    );
  };

  return (
    <StyledBreadcrumbs ref={setRef} animated={animated}>
      <BackLink
        theme={theme ? theme : 'primary'}
        handleOnClick={backLinkClick}
      />

      {breadcrumbsText ? (
        <Text className={theme} id="breadcrumbs-text" onClick={backLinkClick}>
          {breadcrumbsText}
        </Text>
      ) : breadcrumbsTextTablet ? (
        <BreadcrumbsContent id="breadcrumbs-text">
          <Text className={`${theme} isTablet`} onClick={backLinkClick}>
            {breadcrumbsTextTablet}
          </Text>
          <Nav className="isTablet">
            <NavList>
              {parent &&
                parent.map((item, index) => {
                  if (index === 0) {
                    return (
                      <NavItem key={index} className={theme}>
                        <StyledLink
                          className={theme}
                          to={item.handle}
                          exit={{ delay: exitDelay }}
                          onClick={onClick}
                        >
                          {item.title}
                        </StyledLink>
                        <span> /&nbsp;</span>
                      </NavItem>
                    );
                  } else if (index === 1) {
                    return (
                      <NavItem key={index} className={theme}>
                        <StyledLink
                          className={theme}
                          to={`/${item.handle}/`}
                          exit={{ delay: exitDelay }}
                          onClick={onClick}
                        >
                          {item.title}
                        </StyledLink>
                        <span> /&nbsp;</span>
                      </NavItem>
                    );
                  } else if (index === 2) {
                    const link = `/${parent[1].handle}/${item.handle}/`;
                    return (
                      <NavItem key={index} className={theme}>
                        <StyledLink className={theme} to={link}>
                          {item.title}
                        </StyledLink>
                        <span> /&nbsp;</span>
                      </NavItem>
                    );
                  }
                })}
              {current && (
                <NavItem className={theme}>
                  <StyledLink className={theme} to={location.pathname}>
                    {current.title}
                  </StyledLink>
                </NavItem>
              )}
            </NavList>
          </Nav>
        </BreadcrumbsContent>
      ) : (
        <Nav id="breadcrumbs-text">
          <NavList>
            {parent &&
              parent.map((item, index) => {
                if (index === 0) {
                  return (
                    <NavItem key={index} className={theme}>
                      <StyledLink
                        className={theme}
                        to={item.handle}
                        exit={{ delay: exitDelay }}
                        onClick={onClick}
                      >
                        {item.title}
                      </StyledLink>
                      <span> /&nbsp;</span>
                    </NavItem>
                  );
                } else if (index === 1) {
                  return (
                    <NavItem key={index} className={theme}>
                      <StyledLink
                        className={theme}
                        to={`/${item.handle}/`}
                        exit={{ delay: exitDelay }}
                        onClick={onClick}
                      >
                        {item.title}
                      </StyledLink>
                      <span> /&nbsp;</span>
                    </NavItem>
                  );
                } else if (index === 2) {
                  const link = `/${parent[1].handle}/${item.handle}/`;
                  return (
                    <NavItem key={index} className={theme}>
                      <StyledLink className={theme} to={link}>
                        {item.title}
                      </StyledLink>
                      <span> /&nbsp;</span>
                    </NavItem>
                  );
                }
              })}
            {current && (
              <NavItem className={theme}>
                <StyledLink className={theme} to={location.pathname}>
                  {current.title}
                </StyledLink>
              </NavItem>
            )}
          </NavList>
        </Nav>
      )}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
