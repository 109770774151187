import React from 'react';
import { graphql } from 'gatsby';
import Resource from 'components/Layouts/Resource';

const ResourceTemplate = (props) => <Resource {...props} />;

export default ResourceTemplate;

export const resourceQuery = graphql`
  query ($handle: String!) {
    contentfulResourcePage(handle: { eq: $handle }) {
      handle
      parentResource {
        __typename
        ... on ContentfulParentResourcesPage {
          id
          handle
          title
          link___module {
            internalLink {
              ... on ContentfulParentResourcesPage {
                id
                handle
              }
              ... on ContentfulGenericPage {
                id
                handle
              }
              ... on ContentfulBlogPage {
                id
                handle
              }
            }
          }
        }
        ... on ContentfulResourcePage {
          id
          handle
          title
          parent_resources___page {
            handle
            title
          }
          relatedResources {
            handle
            excerpt
            title
            parentResource {
              __typename
              ... on ContentfulParentResourcesPage {
                id
                handle
                link___module {
                  internalLink {
                    ... on ContentfulParentResourcesPage {
                      id
                      handle
                    }
                    ... on ContentfulGenericPage {
                      id
                      handle
                    }
                    ... on ContentfulBlogPage {
                      id
                      handle
                    }
                  }
                }
              }
              ... on ContentfulResourcePage {
                id
                handle
                parent_resources___page {
                  handle
                  title
                }
              }
            }
          }
        }
      }
      resourceBlocks {
        title
        id
        tags
        contentful_id
        body {
          raw
        }
        ctAs {
          openInNewTab
          title
          phoneNumber
          externalLinkOrAnchor
          internalLink {
            ... on ContentfulBlogPage {
              id
              handle
            }
            ... on ContentfulGenericPage {
              id
              handle
            }
            ... on ContentfulParentResourcesPage {
              id
              handle
            }
          }
        }
      }
      relatedResources {
        id
        contentful_id
        excerpt
        title
        handle
        parentResource {
          __typename
          ... on ContentfulParentResourcesPage {
            id
            handle
            link___module {
              internalLink {
                ... on ContentfulParentResourcesPage {
                  id
                  handle
                }
                ... on ContentfulGenericPage {
                  id
                  handle
                }
                ... on ContentfulBlogPage {
                  id
                  handle
                }
              }
            }
          }
          ... on ContentfulResourcePage {
            id
            handle
            parent_resources___page {
              handle
              title
            }
          }
        }
      }
      excerpt
      id
      contentful_id
      title
    }
  }
`;
